<template>
  <Page color="info" title="Schäftli">
    <template slot="help">
      <Markdown :value="helpText" />
    </template>

    <v-container class="mb-4">
      <v-row class="align-center justify-center">
        <SchoolClassPicker outlined text v-model="schoolClass" />

        <v-spacer />

        <v-text-field
          type="search"
          dense
          single-line
          hide-details=""
          v-model="search"
          label="Schüler:in oder Schäftlinummer"
          append-icon="mdi-magnify"
          clearable
        />
      </v-row>
    </v-container>

    <v-card :loading="loading">
      <v-system-bar
        >Schäftli {{ schoolClass ? schoolClass.code : "" }}</v-system-bar
      >
      <v-data-table
        item-key="student.id"
        :loading="loading"
        :headers="headers"
        hide-default-footer
        :items="itemsFiltered"
        disable-pagination
        :items-per-page="-1"
        sort-by="student"
      >
        <template v-slot:item.student="{ item }">
          <PersonItem small :value="item.student" />
        </template>
        <template v-slot:item.locker="{ item }">
          <v-chip-group
            ><v-chip
              @click="search = locker.code"
              v-for="locker of item.current"
              :key="item.student.id + '' + locker.id"
              >{{ locker.code }}</v-chip
            ></v-chip-group
          >
        </template>
        <template v-slot:footer
          ><TableFooter
            :items="itemsFiltered"
            label="Schüler:innen"
            labelSingular="Schüler:in"
          ></TableFooter
        ></template>
      </v-data-table>
    </v-card>
    <router-view />
  </Page>
</template>

<script>
import { defineComponent } from "vue";
import PersonItem from "common/components/PersonItem.vue";
import SchoolClassPicker from "@/components/SchoolClassPicker.vue";
import TableFooter from "common/components/TableFooter.vue";
import { comparePeople, searchPerson } from "common/utils/people.js";

const helpText = `
`;

export default defineComponent({
  components: {
    PersonItem,
    SchoolClassPicker,
    TableFooter,
  },
  data() {
    return {
      helpText,
      loading: false,
      headers: [
        { text: "Schüler:in", value: "student", sort: comparePeople },
        {
          text: "Schäftli",
          value: "locker",
          sortable: false,
        },
      ],
      items: [],
      search: "",
      schoolClass: null,
    };
  },
  computed: {
    itemsFiltered() {
      return this.items.filter(
        (el) =>
          el.student.schoolClass.id == this.schoolClass.id &&
          (!this.search ||
            searchPerson(el.student, this.search) ||
            el.current.some((el) => el.code == this.search))
      );
    },
  },
  watch: {
    schoolClass() {
      this.fetchData();
    },
  },
  methods: {
    async fetchData() {
      if (!this.schoolClass) return;
      this.loading = true;

      this.items = await this.apiList({
        resource: "person/locker",
        query: `schoolClass=${this.schoolClass.id}`,
      });
      this.loading = false;
    },
  },
  created() {},
});
</script>
